<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Register Production {{ height }}
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text :style="`max-height: ${height}px; overflow-y:auto;`">
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    class="elevation-0 mt-7"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            style="background-color: #eeeeee"
                        >
                            <v-col cols="4" class="d-flex align-center">
                                <h2 class="my-n3 d-flex align-center">
                                    ITEMS
                                </h2></v-col
                            >
                            <v-col cols="4" class="d-flex justify-end"
                                ><v-combobox
                                    v-model="selectedProcess"
                                    hide-details
                                    prefix="Process *"
                                    prepend-icon="mdi-tools"
                                    :items="availableProcesses"
                                    item-text="name"
                                    :rules="[rules.required]"
                                    required
                                    class="ma-0 pa-0"
                                    style="width: 100px;"
                                    @change="getProcessMachines"
                                />
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end"
                                ><v-combobox
                                    v-model="selectedMachine"
                                    hide-details
                                    prefix="Machine *"
                                    prepend-icon="mdi-tools"
                                    :items="processMachines"
                                    item-text="name"
                                    required
                                    class="ma-0 pa-0"
                                    style="width: 100px;"
                                    @change="setDefaultMachine"
                                />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`header.partNumber`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="partNumberToFilter"
                            dense
                            class="pt-2"
                            @input="verifyCheckBox"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.code`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">{{ item.code }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.available`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{ item.available }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.machine`]="{ item }">
                        <v-combobox
                            v-model="item.machine"
                            hide-details
                            placeholder="Machine*"
                            :items="item.processMachines"
                            item-text="name"
                            :rules="[rules.required]"
                            required
                            class="mt-n1 pt-0"
                        />
                    </template>
                    <template v-slot:[`item.qty`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-model="item.qtyToSend"
                                hide-details
                                type="number"
                                :rules="[() => maxValue(item)]"
                                @input="verifyCheckBox"
                            />
                        </div>
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-row
                v-if="!partNumberToFilter"
                class="ml-1 d-flex justify-left align-center"
            >
                <v-simple-checkbox
                    color="primary"
                    v-model="setAvailable"
                    @click="setAllAvailable"
                    class="ml-2 d-flex justify-center align center"
                >
                </v-simple-checkbox>
                <small>Select All Available Quantities</small>
            </v-row>
            <v-row v-else class="ml-1 d-flex justify-left align-center">
                <v-simple-checkbox
                    color="primary"
                    v-model="setAvailableInFiltered"
                    @click="setAllAvailableInFiltered"
                    class="ml-2 d-flex justify-center align center"
                >
                </v-simple-checkbox>
                <small>Set Available Quantities In Filtered Items</small>
            </v-row>

            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mb-3 mr-3"
                :disabled="disableButton()"
                @click="openConfirmDialog"
            >
                NEXT
            </v-btn>
        </v-card-actions>
        <v-dialog
            :retain-focus="false"
            v-model="confirmDialog"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeConfirmDialog"
                        color="primary"
                        class="ml-n3 mr-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h2 class="font-weight-regular">
                        Confirm Production -
                        {{ selectedProcess ? selectedProcess.name : '' }}
                    </h2>
                </v-card-title>
                <v-divider class="mx-6" />
                <v-card-text>
                    <v-form v-model="valid">
                        <v-data-table
                            :headers="confirmHeaders"
                            :items="
                                items.filter(
                                    i => i.qtyToSend && i.qtyToSend > 0
                                )
                            "
                            class="elevation-0 mt-7"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            :style="`max-height: ${height}px; overflow-y:auto;`"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                    style="background-color: #eeeeee"
                                >
                                    <v-col
                                        cols="12"
                                        class="d-flex align-center"
                                    >
                                        <h2 class="my-n3 d-flex align-center">
                                            ITEMS
                                        </h2></v-col
                                    >
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.code`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.code }}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.machine`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.machine.name }}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.qty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.qtyToSend }}</p>
                                </div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-5">
                    <v-row class="ma-2">
                        <v-spacer />
                        <v-col cols="3" class="d-flex justify-end pa-0 ">
                            <v-btn
                                color="primary"
                                rounded
                                :loading="loading"
                                @click="registerItems"
                            >
                                SAVE
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import lodash from 'lodash'
import API from '@/services/api'

export default {
    name: 'MultipleRegister',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'availableQty',
                align: 'center',
                sortable: false,
                width: '130',
            },
            {
                text: 'MACHINE',
                value: 'machine',
                align: 'center',
                sortable: false,
                width: '130',
            },
            {
                text: 'QTY TO SEND',
                value: 'qty',
                align: 'center',
                sortable: false,
                width: '130',
            },
        ],
        confirmHeaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'MACHINE',
                value: 'machine',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUANTITY TO REGISTER',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
        ],
        rules: {
            required: v => !!v || 'The value is required',
        },

        availableProcesses: [],
        prevProcess: undefined,
        selectedProcess: undefined,
        confirmCode: undefined,
        code: undefined,
        confirmDialog: false,
        height: 0,
        partNumberToFilter: undefined,
        machines: [],
        items: [],
        processMachines: [],
        selectedMachine: undefined,
        setAvailable: false,
        setAvailableInFiltered: false,
    }),
    computed: {
        filteredItems() {
            //filter available
            let conditions = []
            conditions.push(this.filterAvailable)
            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }
            if (conditions.length > 0) {
                return this.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            return this.items
        },
    },
    mounted() {
        this.items = []
        if (this.user.process) {
            this.availableProcesses = [this.user.process]
            this.selectedProcess = this.user.process
            if (
                this.selectedProcess.description &&
                !this.selectedProcess.name
            ) {
                this.selectedProcess.name = this.selectedProcess.description
                delete this.selectedProcess.description
            }
        }

        this.machines = this.settings.machines
        this.workOrder.items.forEach(item => {
            const copy = lodash.cloneDeep(item)
            this.calculateValues(copy)
            this.items.push(copy)
        })
        this.getProcessMachines()
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        calculateValues(item) {
            try {
                let processes = []
                item.processes.forEach(processId => {
                    let process = item.dataProcesses.find(
                        p => p.id == processId
                    )
                    processes.push(process)
                })
                const processIndex = processes.findIndex(
                    p => p.id == this.user.process.id
                )
                if (processIndex > -1) {
                    let totalProduced = 0
                    let totalDelivered = 0
                    if (item.deliveries) {
                        let delivered = item.deliveries.filter(
                            register =>
                                register.nextProcess.id == this.user.process.id
                        )
                        totalDelivered = delivered.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }

                    if (processIndex == 0) {
                        totalDelivered += item.quantity
                    }

                    if (item.production) {
                        let produced = item.production.filter(
                            register =>
                                register.process.id == this.user.process.id
                        )
                        totalProduced = produced.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }
                    item.availableQty = totalDelivered - totalProduced
                    item.processMachines = this.machines.filter(
                        machine => machine.process == this.user.process.id
                    )
                }
            } catch (error) {
                console.error(error)
            }
        },
        async registerItems() {
            try {
                this.loading = true
                let filteredItems = this.items.filter(
                    i => i.qtyToSend && i.qtyToSend > 0
                )

                let itemsToRegister = filteredItems.map(fi => ({
                    id: fi.id,
                    qtyToSend: fi.qtyToSend,
                    machine: fi.machine,
                }))

                const items = await API.multipleProductionRegister({
                    items: itemsToRegister,
                    workOrderId: this.workOrder.id,
                    process: this.user.process,
                })

                this.$emit('replaceItems', items)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        matchCode() {
            return !!(
                this.code &&
                this.confirmCode &&
                this.code === this.confirmCode
            )
        },

        openConfirmDialog() {
            this.confirmDialog = true
        },

        closeConfirmDialog() {
            this.confirmDialog = false
        },

        close() {
            this.$emit('closeDialog')
        },

        disableButton() {
            const selectedItems = this.items.filter(item => item.qtyToSend > 0)
            const completeForm = selectedItems.every(
                i => i.machine && i.qtyToSend <= i.availableQty
            )
            return selectedItems.length == 0 || !completeForm
        },

        onResize() {
            this.height = window.innerHeight - 260
        },

        filterAvailable(item) {
            return item.availableQty && item.availableQty > 0
        },

        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },
        maxValue(item) {
            return item.qtyToSend > item.availableQty || item.qtyToSend <= 0
                ? 'The quantity to be sent cannot be greater than the quantity available'
                : true
        },
        getProcessMachines() {
            this.processMachines = []
            if (this.selectedProcess) {
                this.processMachines = this.machines.filter(
                    machine => machine.process == this.selectedProcess.id
                )
            }
        },
        setDefaultMachine() {
            this.items.forEach(item => {
                item.machine = this.selectedMachine
            })
        },
        setAllAvailable() {
            this.items.forEach(item => {
                if (
                    this.setAvailable &&
                    item.availableQty &&
                    item.availableQty > 0
                ) {
                    item.qtyToSend = Number(item.availableQty)
                } else {
                    delete item.qtyToSend
                }
            })
        },
        verifyCheckBox() {
            if (this.filteredItems.length > 0) {
                this.setAvailable = !this.items.some(
                    item => (item.qtyToSend || 0) < item.availableQty
                )
                this.setAvailableInFiltered = !this.filteredItems.some(
                    item => (item.qtyToSend || 0) < item.availableQty
                )
            }
        },
        setAllAvailableInFiltered() {
            this.items.forEach(item => {
                if (
                    this.setAvailableInFiltered &&
                    item.availableQty &&
                    item.availableQty > 0 &&
                    item.partNumber
                        .toLowerCase()
                        .includes(this.partNumberToFilter.toLowerCase())
                ) {
                    item.qtyToSend = Number(item.availableQty)
                } else if (
                    !this.setAvailableInFiltered &&
                    item.partNumber
                        .toLowerCase()
                        .includes(this.partNumberToFilter.toLowerCase())
                ) {
                    delete item.qtyToSend
                }
            })
        },
    },
}
</script>

<style></style>
